import React from 'react';
import { setOtherSpouse } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const OtherSpouse = ( props ) => {

    const onChange = value => {
        props.setOtherSpouse(value)
    };

    return (
        <InputStep
            label={'Monthly Employer'}
            onChange={onChange}
            max={99999}
            total={props.otherTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        otherTotal: state.sourceOfIncome.spouse.other.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOtherSpouse: (val) => dispatch(setOtherSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherSpouse);