import React from 'react';
import { setOtherYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const OtherYou = ( props ) => {

    const onChange = value => {
        props.setOtherYou(value)
    };

    return (
        <InputStep
            label={'Monthly Employer'}
            onChange={onChange}
            max={99999}
            total={props.otherTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        otherTotal: state.sourceOfIncome.you.other.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOtherYou: (val) => dispatch(setOtherYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherYou);